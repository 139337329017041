@import "./icomoon.ttf";

.video-player {
	.hide {
		display: none;
	}

	.disable {
		pointer-events: none;
	}

	.unselectable {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	:-webkit-full-screen {
		width: 100%;
		top: 0;
		z-index: -100;
		background: none;
	}

	:-ms-fullscreen {
		width: auto;
		height: auto;
		margin: auto;
	}

	.time-display,
	.duration-display {
		padding: 11px;
		color: white;
		font-weight: normal;
		font-size: 0.9em;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	}

	.time-display {
		float: left;
	}

	.duration-display {
		float: right;
	}

	.live-icon {
		cursor: pointer;
	}
	.live-icon.live {
		color: red !important;
		pointer-events: none;
		cursor: default;
	}

	.btn-play-pause {
		float: left;
		padding: 9px 10px;
		cursor: pointer;
	}

	.control-icon-layout {
		// float: right;
		padding: 9px 10px;
		cursor: pointer;
		height: 20px;
	}

	.btn-fullscreen {
		margin-right: 4px;
	}

	.volumebar {
		float: right;
		width: 70px;
	}

	.video-controller {
		min-height: 60px;
		z-index: 2147483646;
	}

	.video-controller-fullscreen {
		position: fixed;
		z-index: 2147483647;
		width: 100%;
		bottom: 0;
		left: 0;
	}

	.menu,
	.video-controller {
		background-color: rgba(0, 0, 0, 0.2);
	}

	.menu {
		position: absolute;
		right: 60px !important;
		bottom: 40px !important;
		left: unset !important;
		top: unset !important;
		background-color: var(--ion-color-dark);
		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
			li {
				padding: 0 10px;
				cursor: pointer;
			}
		}
		&-item-unselected,
		&-item-selected {
			font-weight: normal;
			font-size: 0.9em;
			font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		}
		&-item-selected,
		&-item-over {
			background-color: var(--ion-color-secondary);
			color: #fff;
			opacity: 1;
		}
	}

	// .menu-sub-menu-title {
	// 	background-color: #191919;
	// 	padding-left: 2px;
	// 	font-weight: bold;
	// 	font-size: 1em;
	// 	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	// }

	#bitrateMenu .menu-sub-menu-title {
		min-width: 150px;
	}

	@font-face {
		font-family: "icomoon";
		src: url("icomoon.ttf") format("truetype");
		font-weight: normal;
		font-style: normal;
	}

	.icon-play,
	.icon-pause,
	.icon-caption,
	.icon-mute-off,
	.icon-mute-on,
	.icon-fullscreen-enter,
	.icon-fullscreen-exit,
	.icon-tracks,
	.icon-bitrate {
		font-family: "icomoon";
		font-size: 20px;
		color: white;
		text-shadow: none;
		-webkit-font-smoothing: antialiased;
	}

	.icon-fullscreen-enter:before {
		content: "\e90b";
	}
	.icon-fullscreen-exit:before {
		content: "\e90c";
	}
	.icon-play:before {
		content: "\e910";
	}
	.icon-pause:before {
		content: "\e911";
	}
	.icon-mute-on:before {
		content: "\e909";
	}
	.icon-mute-off:before {
		content: "\e918";
	}
	.icon-caption:before {
		content: "\e901";
	}
	.icon-bitrate:before {
		content: "\e905";
	}
	.icon-tracks:before {
		content: "\e90a";
	}

	.seekContainer {
		display: flex;
		// overflow: auto;
		// overflow-y: hidden;
		// overflow-x: hidden;
	}

	input[type="range"] {
		-webkit-appearance: none;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		height: 14px;
		border: none;
		margin: 12px 5px;
		padding: 1px 2px;
		border-radius: 5px;
		background: #232528;
		box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
		-webkit-box-shadow: inset 0 1px 0 0 #0d0e0f, inset 0 -1px 0 0 #3a3d42;
		outline: none; /* no focus outline */
	}

	input[type="range"]::-moz-focus-outer {
		border: 0;
	}

	input[type="range"]::-moz-range-track {
		border: inherit;
		background: transparent;
	}

	input[type="range"]::-ms-track {
		border: inherit;
		color: transparent; /* don't drawn vertical reference line */
		background: transparent;
	}

	input[type="range"]::-ms-fill-lower,
	input[type="range"]::-ms-fill-upper {
		background: transparent;
	}

	input[type="range"]::-ms-tooltip {
		display: none;
	}

	/* thumb */
	input[type="range"]::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 15px;
		height: 8px;
		border: none;
		border-radius: 2px;
		background-color: rgb(0, 150, 215);
	}
	input[type="range"]::-moz-range-thumb {
		width: 15px;
		height: 8px;
		border: none;
		border-radius: 2px;
		background-color: rgb(0, 150, 215);
	}

	input[type="range"]::-ms-thumb {
		width: 15px;
		height: 8px;
		border: none;
		border-radius: 2px;
		background-color: rgb(0, 150, 215);
	}

	.thumbnail-container {
		position: absolute;
		text-align: center;
	}

	.thumbnail-elem {
		position: relative;
		box-shadow: 0px 0px 0.9em #000000;
		transform-origin: center bottom;
	}

	.thumbnail-time-label {
		position: relative;
		bottom: 1.8em;
		display: table;
		margin: 0 auto;
		padding: 2px 5px 2px 5px;
		color: #ffffff;
		background-color: rgba(0, 0, 0, 0.7);
		font-size: 12px;
		font-weight: bold;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	}

	.seekbar-complete {
		width: 100%;
		height: 7px;
		margin-top: 16px;
		background: #999a99;
		position: relative;
		// overflow: hidden;
	}

	.seekbar-buffer {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 0%;
		height: 7px;
		background: lightgray;
	}

	.seekbar-play {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 0%;
		height: 7px;
		background: rgb(0, 150, 215);
	}
	.seekbar-complete {
		&:hover {
			.seekbar-play {
				&:after {
					position: absolute;
					content: "";
					right: -8px;
					top: -5px;
					width: 15px;
					height: 15px;
					background-color: var(--ion-color-secondary);
					border-radius: 50%;
				}
			}
		}
	}

	body {
		/* background: black; */
		min-height: 100%;
		margin: 0;
		cursor: default !important;
	}

	.videoContainer {
		background-color: #000;
		min-height: 100vh;
		justify-content: center;
		display: flex;
		@media (max-width: 1024px) {
			min-height: 300px;
		}
		.videocontainerinner {
			width: 100%;
		}
	}

	.dash-video-player {
		width: 100%;
		position: relative;
	}

	.videoElementWeb {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		max-height: 100vh;
		z-index: 0;
		object-fit: contain;
	}

	.video-controller {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.2);
	}

	.btn-play-pause,
	.btn-forward,
	.btn-rewind {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 100;
		cursor: pointer;
	}

	.icon-play,
	.icon-pause {
		font-size: 40px;
	}

	.btn-forward {
		left: calc(50% + 20%);
	}

	.btn-rewind {
		left: calc(50% - 20%);
	}

	.icon-rewind img,
	.icon-forward img {
		width: 28px;
	}

	.seekbar-complete {
		height: 5px;
		border-radius: 10px;
		background: #fff;
		margin: 0;
		margin-right: 14px;
		cursor: pointer;
	}

	.seekbar-play {
		background-color: var(--ion-color-secondary);
		height: 100%;
	}

	.seekbar-buffer {
		height: 100%;
	}

	input[type="range"] {
		background-color: #fff;
		border-radius: 10px;
		box-shadow: none;
		float: none;
	}

	.btn-fullscreen img,
	.control-icon-layout img {
		max-width: 30px;
		max-height: 30px;
		cursor: pointer;
	}

	.bitrateicon {
		position: absolute;
		right: 0;
		z-index: 1;
		width: auto;
	}

	#bitrateMenu {
		background-color: #fff;
		z-index: 200;
		padding: 10px 14px;
		border-radius: 10px;
		right: 10px !important;
		top: 45px !important;
		height: fit-content;
		overflow: auto;
	}

	.menu-sub-menu-title {
		display: none;
	}

	.video-menu-content li {
		font-size: 12px;
		line-height: 16px;
		border: 1px solid var(--ion-color-secondary);
		border-radius: 10px;
		color: #000;
		padding: 6px;
		margin-top: 2px;
		text-align: center;
		display: block !important;
		&.menu-item-selected {
			background-color: var(--ion-color-secondary);
			color: var(--ion-color-secondary-contrast);
			opacity: 1;
		}
	}

	.video-menu-content li:first-child {
		margin-top: 0;
	}

	.video-controller,
	#customControl {
		transition: opacity 0.2s ease-in;
	}

	.seekgrid {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		margin: 0 auto;
		width: 98%;
	}

	.time-display,
	.duration-display {
		padding: 0;
		font-size: 12px;
		white-space: nowrap;
	}

	.time-display:after {
		content: "/";
	}

	.seekContainer {
		width: 100%;
	}

	.volume-wrap {
		display: flex;
		margin-left: 8px;
	}

	.next-prev-wrap .control-icon-layout img {
		width: 24px;
	}

	.bottom-controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.volume-wrap {
			display: flex;
			margin-left: 8px;
		}
		.right-controls {
			display: flex;
		}
		.btn-fullscreen {
			svg {
				transition: all 0.2s ease-in-out;
				&:hover {
					transform: scale(1.06);
				}
			}
		}
	}

	.videoratingwrap {
		margin: 20px;
		padding: 4px 16px;
		position: absolute;
		display: flex;
		flex-direction: column;
		left: 0;
		top: 0;
		&:before {
			content: "";
			width: 4px;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background-color: var(--ion-color-secondary);
			animation: line 1s;
		}
		.rating {
			font-size: 20px;
			color: #fff;
			font-weight: 500;
			letter-spacing: 0.5px;
			&:first-child {
				margin-left: 0;
			}
			&.semi {
				font-size: 14px;
				line-height: 16px;
				font-weight: 300;
				margin-top: 4px;
				letter-spacing: unset;
			}
		}
	}

	@keyframes line {
		0% {
			height: 0px;
		}
		100% {
			height: 100%;
		}
	}
}

@keyframes spinner {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.spinner {
	background-color: rgba(0, 0, 0, 0.4);
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	&:after {
		content: "";
		width: 40px;
		height: 40px;
		border: 5px solid rgba(255, 255, 255, 0.1);
		border-right: 5px solid var(--ion-color-primary-contrast);
		border-radius: 50%;
		position: absolute;
		top: calc(50% - 20px);
		left: calc(50% - 20px);
		animation: spinner 1s linear infinite;
	}
}

#ad-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
  }