/* vars */

$colorRed: #b9090b;
$colorBlack: #0f0f0f;
$colorWhite: #ecf0f1;
$whiteGrey: #a3a3a1;
$iconSize: 50px;
$tileWidth: 280px;
$tileHeight: 160px;

$white: #ffffff;
$black: #000000;
$brand-main: var(--ion-color-primary);
$back-black: var(--ion-color-dark);
$explore-bg: #1E0407;
/* Media Queries Break Point*/

$MQ-sm: 450px;
$MQ-md: 600px;
$MQ-lg: 800px;
$MQ-xl: 1000px;
$MQ-xxl: 1000px;

/* Sizes */

$font-size-sm: 16px;
$font-size-md: 20px;
$font-size-lg: 24px;
$font-size-xl: 28px;
$font-size-xxl: 32px;
$font-size-xxxl: 36px;

$footer-height: 40px;
$navbar-height: 160px;
$navbar-height-MQ-lg: 65px;

$spacing-sm: 4px;
$spacing-md: 8px;
$spacing-lg: 12px;
$spacing-xl: 16px;

$font-size-sm: 12px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-xl: 24px;

/* Colors */

$white: #fff;
$very-light-grey: #e0e0e0;
$light-grey: #b1b1b1;
$grey: #8b8b8b;
$dark-grey: #535353;
$very-dark-grey: #292929;
$black: #000;

$netflix-red: #e50914;
$netflix-black: #221f1f;
$netflix-white: #ecf0f1;
$white-grey: #a3a3a1;
$imdb-yellow: #f3ce13;
$netflix-black-opa: rgba(34, 31, 31, 0.9);


/* mixins */

/* responsive layout */

@mixin maxMediaQueries($arg...) {
  @if length($arg) ==1 {
    @media screen and (max-width: nth($arg, 1)) {
      @content;
    }
  }
  @if length($arg) ==2 {
    @media screen and (max-width: nth($arg, 1)) and (min-width: nth($arg, 2)) {
      @content;
    }
  }
}

@mixin minMediaQueries($arg) {
  @media screen and (min-width: $arg) {
    @content;
  }
}

@mixin grid($cols, $mgn) {
  float: left;
  text-align: center;
  margin-right: $mgn;
  margin-bottom: $mgn;
  width: ((96% - (($cols - 1) * $mgn)) / $cols);
  &:nth-child(#{$cols}n) {
    margin-right: 4%;
  }
  img {
    width: 100%;
  }
}

/* button styles */

@mixin addListBtn {
  .addListBtn {
    background-color: transparent;
    border-radius: 100%;
    border: 2px solid $whiteGrey;
    display: inline-block;
    cursor: pointer;
    color: $colorWhite;
    font-size: 24px;
    padding: 2px 12px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.3s transform;
    margin-right: 10px;
    transform-origin: 50% 50%;
    &:focus {
      outline: 0;
    }
    &:hover {
      border: 2px solid $colorWhite;
      background-color: transparent;
      transform: scale(1.1);
    }
    &:active {
      position: relative;
      top: 1px;
    }
  }
}

@mixin playBtn {
  .playBtn {
    content: "▶";
    background-color: transparent;
    color: $colorWhite;
    border-radius: 100%;
    border: 2px solid $colorWhite;
    display: inline-block;
    cursor: pointer;
    font-size: 24px;
    padding: 22px 10px 22px 15px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.3s transform;
    transform-origin: 50% 50%;
    opacity: 0.3;
    &:focus {
      outline: 0;
    }
    &:hover {
      color: $colorRed;
      border: 2px solid $colorWhite;
      background-color: transparent;
      transform: scale(1.1);
      opacity: 1;
    }
  }
}

@mixin infoBtn {
  .infoBtn {
    content: "ⓘ";
    background-color: transparent;
    color: $colorRed;
    display: inline-block;
    cursor: pointer;
    font-size: 10px;
    padding: 22px 10px 22px 15px;
    text-decoration: none;
    box-sizing: border-box;
    transition: 0.3s transform;
    transform-origin: 50% 50%;
    &:focus {
      outline: 0;
    }
  }
}



/* CSS effects and styles of tiles */

@mixin listingTiles {
  .tile {
    position: relative;
    display: inline-block;
    line-height: 0;
    cursor: pointer;
    padding: 5px;
    overflow: hidden;
    width: $tileWidth;
    height: $tileHeight;
    transition: 0.3s transform;
    transform-origin: 0% 50%;
    &:hover {
      transform: scale(1.7);
    }
    &:hover ~ .tile {
      transform: translate3d(200px, 0, 0);
    }
    .tile-img {
      transition: transform 0.6s ease-out;
      object-fit: cover;
      width: $tileWidth;
      height: $tileHeight;
    }
  }
  .photo-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    /*centering text inside .photo-overlay */
    justify-content: space-between;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.5s;
  }
  .photo-overlay:hover {
    opacity: 1;
  }
  .tile-desc {
    font-size: 0.6em;
    line-height: 1.3;
    margin: 0 0;
  }
  .tile-btn-container {
    margin-top: 130px;
    @include addListBtn;
    .addListBtn {
      font-size: 22px;
      border: 1px solid $whiteGrey;
      padding: 10px 4px;
      &:hover {
        border: 1px solid $colorWhite;
      }
    }
  }
  .playbtn-container {
    margin: 35px 0px 10px 0px;
    text-align: center;
    @include playBtn;
  }
  .infobtn-container {
    margin: 35px 0px 10px 0px;
    text-align: left;
    @include infoBtn;
  }
}

::-webkit-scrollbar {display:none;}