.ripple-wrap{
  position: relative;
  overflow: hidden;
    // border-radius: 8px;
}
span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: var(--ion-color-dark-contrast);
  opacity: 0.2;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}