@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700,800,300italic,400italic,600italic,700italic,800italic);

.cardimg{
  transition: all 0.4s ease-in-out;
  opacity: 0;
  width: 100%;
  min-height: 257.77px;
  @media (max-width: 1024px) {
    min-height: 327.41px;
  }
  @media (max-width: 768px) {
    min-height: 333.33px;
  }
  @media (max-width: 475px) {
    min-height: 120px;
  }
  @media (max-width: 375px) {
    min-height: 120px;
  }
  @media (max-width: 360px) {
    min-height: 120px;
  }
  &.view{
    opacity: 1;
    min-height: auto;
  }
}

.skeletonback{
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  .box-flip{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top: 4px solid var(--ion-color-primary); 
    animation: rotate 2s infinite ease;
  }   

  @keyframes rotate{
    0%{
      transform: none;
    }
    100%{
      transform: rotate(1turn);
    }
  }
}