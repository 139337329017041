@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
//============
//  VARIABLES
//============
$white: white;
$black: rgb(0, 0, 0);
$lightgray: lightgray;
$skyblue: lightskyblue;
//====================
//  MOVIE BACKGROUNDS
//====================
@mixin background($repeat, $position, $size) {
  background-repeat: $repeat;
  background-position: $position;
  background-size: $size;
}
.one {
  background: linear-gradient(to bottom, rgba($black, 0.5), rgba($black, 0.4));
  @include background(no-repeat, center center, cover);
}
//=======================
//    MOVIE INFO
//=======================
.section-mobile {
  width: 100%;
  // height: 200px;
  display: table;
  table-layout: fixed;
  // border-radius: 12px;
  position: relative;
  .mute-btn{
    position: absolute;
    bottom: 10px;
    right: 20px;
    cursor: pointer;
    ion-icon{
      font-size: 25px;
    }
  }
}
@media (max-width: 1024px) {
  .movie-detail-icons{
    position: absolute;
    bottom: 2px;
    padding: 6px;
    border-radius: 8px;
    background-color: rgba(#272C2F, 0.0);
    display: flex;
  }
}
// // controls how featured banner displays on mobile devices-start
// div.section-mobile {
//   border-radius: 16px;
//   // margin: 8px;
//   // max-width: calc(100% - 16px);
//   max-width: 92%;
//   margin-left: auto;
//   margin-right: auto;
// }
// // controls how featured banner displays on mobile devices-end

.section-mobile {
  .movie-info-mobile{
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.movie-info-mobile {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 12px;
  position: absolute;
  bottom: 0;
  color: $white;
  font-weight: unset;
  h4 {
    font-style: normal;
    font-size: 15px;
    font-weight: unset;
    color: #ffffff;
  }
  p {
    font-style: normal;
    color: #ffffff;
    margin-bottom: 0;
    .rating {

      font-style: normal;
      font-size: 10px;
      color: #ffffff;
    }
  }
  .stars {
    margin: 1em 0;
    i {
      color: $skyblue;
      margin: 0 0.1em;
    }
  }
  button {
    border: 2px solid $skyblue;
    background: none;
    color: $skyblue;
    font-size: 0.75em;
    padding: 0.7em 1.3em;
    margin-bottom: 0.85em;
  }
  #play-btn {
    margin-right: 0.4em;
    background: $skyblue;
    color: $white;
  }
  #add-btn {
    border-color: $white;
    color: $white;
    &:hover {
      background: $skyblue;
      color: $white;
      border-color: $skyblue;
    }
  }
}
//======================
//  MEDIA QUERIES
//======================
@media (max-width: 768px) {
  // li:nth-of-type(2),
  // li:nth-of-type(4),
  // li:nth-of-type(8) {
  //   display: none;
  // }
  .movie-info {
    position: relative;
    top: 180px;
    left: 10px;
    h1 {
      font-size: 2em;
    }
    p {
      font-size: 0.95em;
    }
  }
  .section {
    width: 100%;
    height: 310px;
    display: table;
    table-layout: fixed;
  }
}
@media (max-width: 425px) {
  li:nth-of-type(7) {
    display: none;
  }
  .movie-info {
    position: relative;
    top: 180px;
    left: 10px;
    p {

      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 43px;
      color: #ffffff;
      .rating {
  
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 43px;
        color: #ffffff;
      }
    }
  }
  .section {
    width: 100%;
    height: 310px;
    display: table;
    table-layout: fixed;
  }
}

.owl-short .owl-stage-outer {
  height: 200px; // SAME AS CARD HEIGHT
}
  .socialiconswrap {
    position: relative;
    cursor: pointer;
    margin-right: 12px;
    line-height: 9px;
    .btnsdrawer {
      position: absolute;
      bottom: calc(100% + 8px);
      right: calc(-50% - 3px);
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      &.show {
        max-height: 400px;
      }
      svg {
        width: 50px;
        height: 50px;
        @media (max-width: 1024px) {
          width: 32px;
          height: 32px;
        }
      }
    }
  }