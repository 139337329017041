@import "../../../../assets/css/vars.scss";

.section{
	width: 100%;
    margin: 10px;
    border-radius: 12px;
    overflow: hidden;
}
.carousal-bar-name{
	margin-bottom: 12px;
	.carosuel-icon {
		// color: #fff;
		color: var(--ion-color-titletext);
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 0;
		font-weight: 500;
		letter-spacing: 0.4px;
	}
}

.border-bottom-big{
	.slick-arrow{
		display: none !important;
	}
	.movie-detail-icons,
	.movie-card-long-video{
		display: none;
	}
}

.carousal-container {
	width: 94%;
	padding: 20px;
	@media (max-width: 768px) {
		// width: 95%;
		// padding: 10px 6px;
		width: 88%;
		padding: 12px 12px;
		background: var(--ion-color-background-contrast);
		margin-bottom: 12px;
		border-radius: 16px;
	}
	.slick-prev:before, .slick-next:before{
		color: var(--ion-color-primary);
		font-size: 25px;
	}
	@media (max-width: 900px) {
		.slick-prev{
			display: none !important;
		}
		.slick-next{
			display: none !important;
		}
	}
}

.skeleton-card-normal {
	width: 70px;
	height: 180px;
	padding-left: 10px;
	padding-right: 10px;
}
.skeleton-card-short {
	width: 110px;
	height: 190px;
	padding-left: 10px;
	padding-right: 10px;
}
.skeleton-card-long-mobile {
	height: 200px;
	padding-left: 10px;
	padding-right: 10px;
}
.skeleton-card-long {
	height: 310px;
	padding-left: 10px;
	padding-right: 10px;
}

.slick-track{ margin: 0 !important; display: flex; align-items: center;}

.border-bottom-big{
	@media (min-width: 769px) {
		.slick-slide{
			transform: scale(0.95);
			opacity: 0.5;
			transition: all 0.5s ease-in-out;
			&.slick-center{
				transform: scale(1);
				opacity: 1;
			}
		}
	}
}

.slick-dots{
	position: unset !important;
	li{
		margin: 0 !important;
		button{
			&::before{
				// color: #fff !important;
				color: var(--ion-color-primary) !important;
				font-size: 10px !important;
			}
		}
	}
}