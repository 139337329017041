@import "../../../../assets/css/vars.scss";

.menu-content {
	background-color: $back-black;
}
.explore-section-wrap {
	display: flex;
	flex-wrap: wrap;
	// margin: 0 px;
	justify-content: space-evenly;
	.explore-card-wrap {
		flex: 0 1 25%;
		max-width: calc(25% - 26px);
		@media (max-width: 1279px) {
			flex: 1 1 33%;
			max-width: calc(33% - 24px);
		}
		@media (max-width: 1024px) {
			flex: 1 1 50%;
			max-width: calc(50% - 26px);
		}
		@media (max-width: 640px) {
			flex: 0 1 100%;
			// max-width: 100%;
			max-width: 96%;
			border-radius: 16px;
		}
		// margin: 12px;
		border-radius: 0 px;
		border: 0 px solid var(--ion-color-primary);
		margin-top: 12px;
		// background: var(--ion-color-dark-shade);
		background: var(--ion-color-background-contrast);
		img {
			max-width: 100%;
			// border-radius: 0 px;
			border-radius: 12px 12px 0px 0px;
		}
		.banner-desc{
			padding: 12px;
			.title {
				color: var(--ion-color-titletext);
				font-size: 20px;
				line-height: 28px;
				margin-top: 4px;
				font-weight: 400;
				margin-bottom: 4px;
			}
			.movie-details-wrap {
				list-style: none;
				padding: 0;
				margin: 0;
				margin-top: 12px;
				display: flex;
				.movie-details-list {
					color: var(--ion-color-sub-text);
					font-size: 14px !important;
					line-height: 14px;
					font-weight: 200;
					&:not(:first-child) {
						margin-left: 8px;
					}
				}
			}
			.movie-desc {
				color: var(--ion-color-sub-text);
				font-size: 12px;
				line-height: 18px;
				margin-top: 12px;
				font-weight: 100;
			}
			.created-wrap,
			.cast-wrap {
				color: $white;
				font-size: 10px;
				line-height: 14px;
				font-weight: 300;
			}
			.created-wrap {
				margin-top: 12px;
			}
		}
	}
}
