@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;600;700&display=swap");

.successpayment {
  color: var(--ion-color-dark-dark);
  background-color: #fff;
  padding: 12px 12px 0;
  width: 100%;
  text-align: center;
  .main-heading {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    &-sub {
      font-size: 14px;
      line-height: 20px;
      margin-top: 12px;
      font-weight: 400;
      color: #999;
    }
  }
}