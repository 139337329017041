.seriesList {
  padding: 12px;
  padding-bottom: 32px;
  .series {
    // display: flex;
    overflow: auto;
    .episodeCard {
      cursor: pointer;
      &:not(:first-child) {
        margin-left: 16px;
      }
      .episodeImg {
        height: 150px;
      }
      img {
        height: 150px;
        width: 240px;
        border-radius: 10px;
      }
      .episodeInfo {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        .title-font {
          font-weight: 700;
          font-size: 18px;
          color: var(--ion-color-primary);
        }
        .description-font {
          margin-top: 8px;
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;
          color: var(--ion-color-dark-contrast);
        }
      }
    }
  }
}
