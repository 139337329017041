.settingsContainer {
  box-sizing: border-box;
  .settings {
    .infoBlock {
      .infoBlockRow {
        padding: 16px;
        .infoType {
          flex: unset;
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.8);
        }
        .infoValue {
          font-size: 14px;
          line-height: 18px;
          color: var(--ion-color-primary);
        }
        select {
          background-color: transparent;
          border: 0px solid var(--ion-color-primary);
          border-radius: 2px;
          padding-right: 12px;
          outline: none;
        }
      }
    }
    .Infoblockbox {
      box-sizing: inherit;
      border-radius: 10px;
      background: #1f1d33;
    }
    .InfoBlockSubTitle {
      font-size: 18px;
      color: var(--ion-color-white);
      padding-top: 20px;
      padding-bottom: 12px;
    }
  }
}
ion-toggle {
  --background: #000;
  --background-checked: #000;
  --handle-background: #1e1e32;
  --handle-background-checked: var(--ion-color-secondary);
}