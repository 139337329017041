// .genrelist {
//   box-sizing: border-box;
.headCloseButton {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pageName h3{
  color: var(--ion-color-titletext);
}
  .movie-card {
    display: flex;
    flex-wrap: wrap;
    .cardlinking {
      min-width: unset;
      flex: 0 0 50%;
      box-sizing: border-box;
      &:nth-child(2n-1){
        padding-right: 6px;
      }
      &:nth-child(2n){
        padding-left: 6px;
      }
      &:nth-child(n+3){
        padding-top: 12px;
      }
      .movie-short{
        .flag{
          display: flex;
          padding: 4px 0px 2px;
        }
        h5{
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    @media (min-width:1023px) {

      .movie-normal .cardimg {
        max-height: 240px;
      }
    }
  }
// }
