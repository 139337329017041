.searchresultpage{
  box-sizing: border-box;
  .searchbar-wrap{
    ion-icon{
      width: 28px;
      right: 20px;
      font-size: 30px;
    }
    .searchbar{
      width: 100%;
      font-size: 20px;
      line-height: 26px;
      height: auto;
      padding: 10px 20px;
      border: 2px solid var(--ion-color-primary);
      transition: all 0.2s ease-in-out;
      &:focus {
        border-color: var(--ion-color-primary);
      }
    }
    @media (max-width: 1025px){
      display: block;
    }
  }
  @media (max-width: 1025px){
    .searchcontainer{
      width: 100%;
    }
  }
}