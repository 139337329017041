
.videocontainerinner {
    width: 100%;
}
.dash-video-player-safari {
    width: 100%;
    position: relative;
}

.videoElement {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    max-height: 100vh;
    z-index: 0;
    object-fit: contain;
    @media (max-width: 1024px) {
        max-height: calc(100vh - 80px);
    }
}