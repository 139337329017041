@import "../../../../../src/assets/css/themes/normal.scss";
.coins-container {
  display: flex;
  justify-content: center;
  // background: rgba(0, 0, 0, 0.75);
}
.coins {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  width: 50%;
  @media(max-width:768px){
    width: 100%;
  }
}
.coin-head-block{
  background-color: var(--ion-color-primary);
  padding: 30px;
  background: url('https://private-ott.ams3.cdn.digitaloceanspaces.com/background.jpeg');
  .coin-box{
    background-color: var(--ion-color-dark1);
    border: 1px solid var(--ion-color-dark1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    .total-coins{
      font-size: 48px;
      line-height: 57px;
      color: var(--ion-color-primary);
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        margin-right: 12px;
      }
    }
    .coins-earned{
      font-size: 24px;
      line-height: 28px;
      color: var(--ion-color-primary);
      font-weight: 700;
    }
    .coins-desc{
      color: 14px;
      line-height: 18px;
      color: var(--ion-color-primary);
      margin-top: 12px;
    }
  }
}

.coin-card-wrapper{
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  .coin-card{
    background: var(--ion-color-dark1);
    border: 2px solid var(--ion-color-primary);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px 8px;
    flex: 1 1 calc(50% - 20px);
    margin-bottom: 12px;
    &:nth-child(2n){
      margin-left: 12px;
    }
    .product-details{
      position: relative;
      margin-bottom: 12px;
      max-width: 134px;
      height: 134px;
      img{
        width: 100%;
      }
      &:after{
        content:"";
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--ion-color-primary);
        animation: example 0.5s forwards;
        animation-delay: 2.5s;
      }
    }
    .product-desc{
      color: var(--ion-color-secondary-contrast);
      font-size: 9px;
      line-height: 11px;
      text-align: center;
    }
    .product-price{
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      .actual-price{
        font-size: 9px;
        line-height: 11px;
        color: var(--ion-color-secondary-contrast);
      }
      .discounted-price{
        font-size: 9px;
        line-height: 11px;
        color: var(--ion-color-secondary-contrast);
        margin-left: 20px;
      }
    }
  }
}

@keyframes example {
  from { transform: scale(1);}
  to {transform: scale(0)}
}