.footer-wrap{
	padding: 20px 24px;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	align-items: center;
	.copyright-text{
		color: var(--ion-color-primary);
	}
	.social-links{
		.sociallogo{
			&:not(:first-child){
				margin-left: 12px;
			}
		}
	}
	@media (max-width: 768px) {
		display: none;
	}
}