.coupon-container {
  &-header{
    justify-content: flex-end;
    img{
      max-width: 120px;
    }
    @media (max-width: 768px) {
      justify-content: center;
      img{
        max-width: 150px;
        margin-top: 10px;
      }
    }
  }
  &-body{
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    color: #fff;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      padding: 24px;
    }
    &-left{
      @media (max-width: 768px) {
        margin-top: 50px;
      }
      flex: 0 0 50%;
      max-width: 45%;
      margin: 0 auto;
      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      &-heading{
        font-size: 36px;
        line-height: 50px;
        text-align: center;
        margin-bottom: 40px;
        @media (max-width: 768px) {
          font-size: 24px;
          line-height: 36px;
        }
      }
      .steps-wrap{
        .step{
          &-text{
            font-size: 21px;
            line-height: 30px;
            font-weight: 300;
            flex: 1 1 50%;
            text-align: center;
            @media (max-width: 768px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
          &-img{
            flex: 0 0 200px;
            @media (max-width: 768px) {
              flex: 0 0 100px;
            }
            img{
              max-width: 100%;
            }
          }
          &:not(:first-child){
            margin-top: 40px;
          }
        }
      }
    }
    &-right{
      flex: 0 0 50%;
      max-width: 45%;
      margin: 0 auto;
      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      &-heading{
        font-size: 56px;
        line-height: 80px;
        text-align: center;
        font-weight: 300;
        margin-bottom: 60px;
        @media (max-width: 768px) {
          font-size: 26px;
          line-height: 36px;
          margin-bottom: 24px;
        }
      }
      .coupon-generator-box{
        flex-direction: column;
        .coupon-code-wrap{
          color: #EFCF02;
          width: 100%;
          .exclusive-code{
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            span{
              font-size: 12px;
              line-height: 18px;
              background-color: #fff;
              border-radius: 4px;
              padding: 2px 8px;
              margin-left: 4px;
              color: #000;
              cursor: pointer;
              &:hover{
                background-color: #fafafa;
              }
            }
            @media (max-width: 768px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
          .coupon-code{
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            margin-top: 12px;
            @media (max-width: 768px) {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
            }
          }
          .link{
            text-align: center;
            margin-top: 30px;
            a{
            font-size: 28px;
            line-height: 40px;
            color: #EFCF02;
            @media (max-width: 768px) {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
            }
              &:hover{
                text-decoration: underline;
              }
            }
          }
        }
        ion-button{
          width: 80%;
          margin: 0 auto;
          height: 90px;
          font-size: 30px;
          color: #000;
          @media (max-width: 768px) {
            width: 100%;
            height: 40px;
            font-size: 16px;
          }
        }
      }
      .redeem-coupon-steps{
        color: var(--ion-color-primary);
        margin-top: 80px;
        @media (max-width: 768px) {
          margin-top: 40px;
        }
        .heading{
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 24px;
        }
        .users{
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 12px;
        }
        .couponsteps{
          padding: 0;
          margin: 0;
          margin-bottom: 20px;

            list-style-type: disc;
            padding-left: 24px;
          li{
            font-size: 14px;
            line-height: 20px;
            font-weight: 300;
          }
        }
      }
    }
  }
}