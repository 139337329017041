@import "assets/css/vars.scss";

@media (max-width: 1024px) {
  .cast-card {
    padding: 5%;
    .cast-img-wrap{
      max-width: 150px;
      max-height: 150px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 8px;
      margin-bottom: 8px;
      @media (max-width: 525px) {
        width: 90px;
        height: 90px;
      }
      .cast-img {
        border-radius: 8px;
        height: 100%;
        width: 100% !important;
        @media (max-width: 525px) {
          height: auto;
        }
      }
    }
    .cast-name {
      width: 100%;
      margin-top: 2px;
      font-size: 12px;
      line-height: 18px;
      display: block;
      text-align: center;
      color: var(--ion-color-dark-contrast);
    }
  }
}
