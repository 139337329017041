@import "./icomoon.ttf";

.hide {
    display: none;
}

.disable {
    pointer-events: none;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

:-webkit-full-screen {
    width: 100%;
    top: 0;
    z-index: -100;
    background: none;
}

:-ms-fullscreen {
    width: auto;
    height: auto;
    margin: auto;
}

.time-display,
.duration-display {
    padding: 11px;
    color: white;
    font-weight: normal;
    font-size: 0.9em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.time-display {
    float: left;
}

.duration-display {
    float: right;
}

.live-icon {
    cursor: pointer;
}
.live-icon.live {
    color: red !important;
    pointer-events: none;
    cursor: default;
}

.btn-play-pause {
    float: left;
    padding: 9px 10px;
    cursor: pointer;
}

.control-icon-layout {
    float: right;
    padding: 9px 10px;
    cursor: pointer;
    height: 20px;
}

.btn-fullscreen {
    margin-right: 4px;
}

.volumebar {
    float: right;
    width: 70px;
}

.video-controller {
    min-height: 60px;
    z-index: 2147483646;
}

.video-controller-fullscreen {
    position: fixed;
    z-index: 2147483647;
    width: 100%;
    bottom: 0;
    left: 0;
}

.menu,
.video-controller {
    background-color: rgba(0, 0, 0, 0.2);
}

.menu-item-unselected,
.menu-item-selected {
    font-weight: normal;
    font-size: 0.9em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.menu-item-unselected {
    color: white;
}

.menu-item-over,
.menu-item-selected {
    background-color: white;
    color: black;
}

.menu-sub-menu-title {
    background-color: #191919;
    padding-left: 2px;
    font-weight: bold;
    font-size: 1em;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.menu-item-selected {
    opacity: 0.7;
}

.menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu li {
    padding: 0 10px;
    cursor: pointer;
}

.menu {
    position: absolute;
}

#bitrateMenu .menu-sub-menu-title {
    min-width: 150px;
}

@font-face {
    font-family: "icomoon";
    src: url("icomoon.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.icon-play,
.icon-pause,
.icon-caption,
.icon-mute-off,
.icon-mute-on,
.icon-fullscreen-enter,
.icon-fullscreen-exit,
.icon-tracks,
.icon-bitrate {
    font-family: "icomoon";
    font-size: 20px;
    color: white;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
}

.icon-fullscreen-enter:before {
    content: "\e90b";
}
.icon-fullscreen-exit:before {
    content: "\e90c";
}
.icon-play:before {
    content: "\e910";
}
.icon-pause:before {
    content: "\e911";
}
.icon-mute-on:before {
    content: "\e909";
}
.icon-mute-off:before {
    content: "\e918";
}
.icon-caption:before {
    content: "\e901";
}
.icon-bitrate:before {
    content: "\e905";
}
.icon-tracks:before {
    content: "\e90a";
}

.seekContainer {
    display: flex;
}

.thumbnail-container {
    position: absolute;
    text-align: center;
}

.thumbnail-elem {
    position: relative;
    box-shadow: 0px 0px 0.9em #000000;
    transform-origin: center bottom;
}

.thumbnail-time-label {
    position: relative;
    bottom: 1.8em;
    display: table;
    margin: 0 auto;
    padding: 2px 5px 2px 5px;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    font-weight: bold;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.seekbar-buffer {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0%;
    height: 7px;
    background: lightgray;
}

.seekbar-play {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 0%;
    height: 7px;
    background: rgb(0, 150, 215);
}
.videoContainerSafari {
    background-color: #000000;
    min-height: 100vh;
    justify-content: center;
    display: flex;
    @media (max-width: 1024px) {
        min-height: 300px;
    }
}
.videocontainerinner {
    width: 100%;
}
.dash-video-player-safari {
    width: 100%;
    position: relative;
}

.videoElement {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    max-height: 100vh;
    z-index: 0;
    object-fit: contain;
    @media (max-width: 1024px) {
        max-height: calc(100vh - 80px);
    }
}

.video-controller {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.btn-play-pause,
.btn-forward,
.btn-rewind {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    cursor: pointer;
}

.icon-play,
.icon-pause {
    font-size: 40px;
}

.btn-forward {
    left: calc(50% + 20%);
}

.btn-rewind {
    left: calc(50% - 20%);
}

.icon-rewind img,
.icon-forward img {
    width: 28px;
}

.seekbar-play {
    background-color: var(--ion-color-secondary);
    height: 100%;
}

.seekbar-buffer {
    height: 100%;
}

.btn-fullscreen img,
.control-icon-layout img {
    max-width: 30px;
    max-height: 30px;
    cursor: pointer;
}

.bitrateicon {
    position: absolute;
    right: 0;
    z-index: 1;
    width: auto;
}

#bitrateMenu {
    background-color: #fff;
    z-index: 200;
    padding: 10px 14px;
    border-radius: 10px;
    right: 10px !important;
    top: 45px !important;
    max-height: 200px;
    overflow: auto;
    position: fixed !important;
    left: unset !important;
}

.menu-sub-menu-title {
    display: none;
}

.video-menu-content li {
    font-size: 12px;
    line-height: 16px;
    border: 1px solid var(--ion-color-secondary);
    border-radius: 10px;
    color: #000;
    padding: 6px;
    margin-top: 2px;
    text-align: center;
    &.menu-item-selected {
        background-color: var(--ion-color-secondary);
        color: var(--ion-color-secondary-contrast);
        opacity: 1;
    }
}

.video-menu-content li:first-child {
    margin-top: 0;
}

.video-controller,
#customControl {
    transition: opacity 0.2s ease-in;
}

.seekgrid {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 auto;
    width: 98%;
}

.time-display,
.duration-display {
    padding: 0;
    font-size: 12px;
    white-space: nowrap;
}

.time-display:after {
    content: "/";
}

.seekContainer {
    width: 100%;
}

.volume-wrap {
    display: flex;
    margin-left: 8px;
}

.next-prev-wrap .control-icon-layout img {
    width: 24px;
}

.video-player .seekbar-play {
    background-color: var(--ion-color-secondary);
    height: 100%;
}
.seekbar {
    -webkit-appearance: none;
    width: 100%;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    &:focus {
        outline: none;
    }
    &.seekbar-complete {
        height: 5px !important;
        border-radius: 10px;
        margin: 0;
        margin-right: 14px;
        cursor: pointer;
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        background: var(--ion-color-secondary);
        cursor: pointer;
        border-radius: 50%;
        -webkit-appearance: none;
        margin-top: -5px;
    }
    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        border-radius: 10px;
    }
    &::-webkit-range-progress,
    &::-moz-range-progress {
        background: var(--ion-color-secondary);
        height: 1em;
    }
}
