.load-wrap {
	padding: 20px 20px 20px;
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	height: calc(100vh - 115px);
	align-items: center;
	@media (max-width: 768px) {
		
	}
	.loader {
		padding-bottom: 10px;
		position: relative;
		&:after {
			content: "";
			width: 25px;
			height: 6px;
			border-radius: 2px;
			background-color: var(--ion-color-primary);
			position: absolute;
			bottom: 0;
			left: 0;
			animation: loadingBorder 4s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
			@media (max-width: 768px) {
				animation: loadingBorderm 4s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
			}
		}
		@media (max-width: 768px) {
			font-size: 24px;
			line-height: 32px;
		}
	}
}

@keyframes loadingBorder {
	0%,
	100% {
		transform: translate(50px, 0);
	}

	50% {
		transform: translate(300px, 0);
		background-color: var(--ion-color-secondary);
		width: 40px;
	}
}
@keyframes loadingBorderm {
	0%,
	100% {
		transform: translate(20px, 0);
	}

	50% {
		transform: translate(200px, 0);
		background-color: var(--ion-color-secondary);
		width: 40px;
	}
}
