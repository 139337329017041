.aboutUs-img {
  margin: 20px;
  display: flex;
  justify-content: center;
  width: 50%;
}

.body-text {
    font-size: 14px ;
    font-weight: 400;
    text-align: left;
    color: #bbbbbb;
    margin-left: 20px;
    margin-right: 20px;
    // text-align: justify;
  }
