.series-mobile {
  padding: 12px;
  .episodeCard-mobile {
    display: flex;
    margin-top: 16px;
    .episodeImg-mobile{
      flex-basis: 60%;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      img {
        height: auto;
        width: 100%;
        max-height: 200px;
        border-radius: 4px;
      }
      .mask{
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .play-icon{
          width: 32px;
        }
      }
    }
    .episodeInfo-mobile {
      margin-left: 12px;
      flex-basis: 60%;
      .title-font {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: var(--ion-color-white);
        margin-top: 12px;
      }
      .description-font {
        margin-top: 4px;
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}